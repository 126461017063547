export const addNewB2bCommand = async (body: any) => {
  try {
    const res = await fetch(
      process.env.NEXT_PUBLIC_CARDS_API + "/api/v1/commands/b2b",
      {
        method: "POST",
        body: JSON.stringify(body),
        credentials: "include",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    if (!res.ok) return null;
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const sendContact = async (body: any) => {
  try {
    const res = await fetch(
      process.env.NEXT_PUBLIC_CARDS_API + "/api/v1/contact",
      {
        method: "POST",
        body: JSON.stringify(body),
        credentials: "include",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    if (!res.ok) {
      return null;
    }
    const data = await res.json();
    return data;
  } catch (error) {
    return null;
  }
};

export const subscribeToNews = async (email: string) => {
  try {
    const res = await fetch(
      process.env.NEXT_PUBLIC_CARDS_API + "/api/v1/contact/subscribe",
      {
        method: "POST",
        body: JSON.stringify({ email }),
        credentials: "include",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    if (!res.ok) {
      return null;
    }
    const data = await res.json();
    return data;
  } catch (error) {
    return null;
  }
};

export const addNewB2bRewards = async (body: any) => {
  try {
    const res = await fetch(
      process.env.NEXT_PUBLIC_REWARDS_API + "/orders/b2b",
      {
        method: "POST",
        body: JSON.stringify(body),
        credentials: "include",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    if (!res.ok) return null;
    const data = await res.json();
    return data;
  } catch (error) {
    return null;
  }
};
